import React, { Component } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import Menu from './Menu';
import HamburgerButton from './HamburgerButton';
import { ImgLogo } from './svg';
import { trackPage, trackEvent, trackScroll } from '../utils/ga';

export default class Header extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      menuVisibility: "",
      headerHideEnabled: true,
      prevScrollPos: window.pageYOffset,
      minScrollPos: "50"
    };
    
    this.handleClick = this.handleClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);  
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(el) {
    var currScrollPos = window.pageYOffset;
    var prevScrollPos = this.state.prevScrollPos;
    
    // Show/hide top menu bar
    if (prevScrollPos >= currScrollPos || this.state.minScrollPos > currScrollPos) {
      this.setState({
        headerVisibility: "header-show"
      });
    } else {
      this.setState({
        headerVisibility: "header-hide"
      });
    }

    // Track scrolling progress with Google Analytics
    trackScroll('footer');

    // Update state
    this.setState({
      prevScrollPos: currScrollPos
    }); 
  }



  handleClick(page) {
    // Track site navigation with Google Analytics
    trackPage(page); 

    // Hide menu
    this.toggleMenu();
  }
     
  toggleMenu() {
    if (this.state.menuVisibility === "" || this.state.menuVisibility === "hide")  {
      
      // Track event with Google Analytics
      trackEvent("NAVIGATION", "Open Menu", "toggleMenu");

      this.setState({
          menuVisibility: "show"
      })
    }
    else {

      // Track event with Google Analytics
      trackEvent("NAVIGATION", "Close Menu", "toggleMenu");

      this.setState({
          menuVisibility: "hide"
      })
    }
  }

  render() {
    return (
        <div className={"header " + this.state.menuVisibility + " " + ((this.state.menuVisibility !=="show") ? this.state.headerVisibility : "")}>
          <Menu 
            handleClick={ this.handleClick }
            menuVisibility={ this.state.menuVisibility }
            handleScroll={ this.handleScroll }
          />
          <NavHashLink to="/"
            onClick={ el => { document.body.scrollIntoView({behavior: 'smooth', block: 'start' }); trackPage('/home'); } }
            className="home-link">
            <ImgLogo />
          </NavHashLink>

          <HamburgerButton 
            toggleMenu={this.toggleMenu} 
            menuVisibility={this.state.visible}
          />

        </div>
    );
  }
}