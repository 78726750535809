import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { trackEvent } from '../../utils/ga';

export default class Footer extends Component {
  
  render() {
    return (
      <div id="footer" className="footer">

        <a href="https://goo.gl/maps/uJHW4z3yRchMogKP6" 
           target="_blank" 
           rel="noopener noreferrer" 
           onClick={ () => { trackEvent("CONTACT", 'Click footer address link to Google Maps', "button"); } }
        >
          <div className="footer-text"><strong>Kayenta Consulting AB&nbsp;&nbsp;</strong></div>
          <div className="footer-text">Wallingatan 32, 111 24 Stockholm</div>
          
        </a>
        <div className="footer-navigation">
          <Link to="/integritetspolicy">Integritetspolicy</Link>
        </div>
      </div>
    );
  }
}