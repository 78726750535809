import React, { useState, useEffect, useRef, useCallback } from 'react';

import { StartPage } from './StartPage';
import { Kundcase } from './Kundcase';
import { ModigaForetag } from './ModigaForetag';
import { AnalytiskaFormagan } from './AnalytiskaFormagan';
import { Utvecklar } from './Utvecklar';
import { Vi } from './Vi';
import { FortsattaResan } from './FortsattaResan';

import { StoryLine } from '../StoryLine';

import { trackEvent, trackScroll } from '../../utils/ga';

export const Home = ({setCurrentChapter}) => {

  const [activeCustomerCaseId, setActiveCustomerCaseId] = useState(null)
  const [defaultCustomerCaseId, setDefaultCustomerCaseId] = useState(1)
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset) 

  const refKundcase = useRef()
  const refModigaForetag = useRef()
  const refAnalytiskaFormagan = useRef()
  const refUtvecklar = useRef()
  const refVi = useRef()

  useEffect( () => {

    window.addEventListener('scroll', handleScroll);  

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  },[])

  const handleScroll = (el) => {
    const currScrollPos = window.pageYOffset;
    const windowHeight = window.innerHeight/2;

    // Track scrolling progress with Google Analytics
    trackScroll('kundcase', prevScrollPos);
    trackScroll('modiga-foretag', prevScrollPos);
    trackScroll('analytiska-formagan', prevScrollPos);
    trackScroll('utvecklar', prevScrollPos);
    trackScroll('vi', prevScrollPos);

    // Keep track of current chapter
    let chapter = 0

    if (refVi.current.getBoundingClientRect().top - windowHeight < 0) {
      chapter = 4
    } else if (refUtvecklar.current.getBoundingClientRect().top - windowHeight < 0) {
      chapter = 3
    } else if (refAnalytiskaFormagan.current.getBoundingClientRect().top - windowHeight < 0) {
      chapter = 2
    } else if (refModigaForetag.current.getBoundingClientRect().top - windowHeight < 0) {
      chapter = 1
    }

    // Update state
    setPrevScrollPos(prev => currScrollPos)
    setCurrentChapter(prev => chapter)
  }

  const setActiveCustomerCase = useCallback((id, e) => {

    var caseId;

    if (activeCustomerCaseId === id) {
      
      // Track event with Google Analytics
      trackEvent("INTERACT", "Close all customer cases", "setActiveCustomerCase");

      caseId = null;

    } else {
      // Track event with Google Analytics
      trackEvent("INTERACT", "Open customer case " + id, "setActiveCustomerCase");

      caseId = id;
    }

    // Update state
    setActiveCustomerCaseId(prev => caseId );
  }, [activeCustomerCaseId])

  const isActive = useCallback((id) => {
    return activeCustomerCaseId === id;
  }, [activeCustomerCaseId])

  const noneIsActive = useCallback((id) => {
    return ((activeCustomerCaseId === null) ? "none-is-active" : "");
  }, [activeCustomerCaseId])
  

  return (
    <div className="Home" >  

      <StartPage 
        nextPage = { refKundcase }
        />

      <Kundcase
        ref = { refKundcase }
        activeCustomerCaseId = { activeCustomerCaseId }
        setActiveCustomerCase = { setActiveCustomerCase }
        isActive = { isActive } 
        noneIsActive = { noneIsActive }
        nextPage = { refModigaForetag } 
        />

      <StoryLine chapter={1} activeCustomerCaseId = { activeCustomerCaseId } />

      <ModigaForetag
        ref = { refModigaForetag }
        chapter={1} 
        activeCustomerCaseId = { activeCustomerCaseId }
        defaultCustomerCaseId = { defaultCustomerCaseId }
        setActiveCustomerCase = { setActiveCustomerCase }
        isActive = { isActive } 
        noneIsActive = { noneIsActive }
         />

      <StoryLine chapter={2} activeCustomerCaseId = { activeCustomerCaseId } />
      
      <AnalytiskaFormagan
        ref = { refAnalytiskaFormagan }
        chapter={2} 
        activeCustomerCaseId = { activeCustomerCaseId }
        defaultCustomerCaseId = { defaultCustomerCaseId }
        setActiveCustomerCase = { setActiveCustomerCase }
        isActive = { isActive } 
        noneIsActive = { noneIsActive }
         />
      
      <StoryLine chapter={3} activeCustomerCaseId = { activeCustomerCaseId } />
      
      <Utvecklar
        ref = { refUtvecklar }
        chapter={3} 
        activeCustomerCaseId = { activeCustomerCaseId }
        defaultCustomerCaseId = { defaultCustomerCaseId }
        setActiveCustomerCase = { setActiveCustomerCase }
        isActive = { isActive } 
        noneIsActive = { noneIsActive }
         />
      
      <StoryLine chapter={4} activeCustomerCaseId = { activeCustomerCaseId } />
      
      <Vi
        ref = { refVi }
        chapter={4} 
        activeCustomerCaseId = { activeCustomerCaseId }
        defaultCustomerCaseId = { defaultCustomerCaseId }
        setActiveCustomerCase = { setActiveCustomerCase }
        isActive = { isActive } 
        noneIsActive = { noneIsActive }
         />

      <FortsattaResan />

    </div>
);
}