import React, { Component } from 'react';
import { IconPhone, IconMail, IconLinkedin } from "./svg";
import { trackEvent } from '../utils/ga';

export default class Profile extends Component {
  
  constructor(props) {
    super(props)
    this.state = { hover: false, 
                   tooltipText: "" 
                 }
    this.getImageName = this.getImageName.bind(this);
  }

  handleMouseIn(text) {
    this.setState({ hover: true })
    this.setState({ tooltipText: text })
  }

  handleMouseOut() {
    this.setState({ hover: false, tooltipText: "" })
  }

  getImageName(input) {
    let arr = input.match(/(.*)@/)[1].replaceAll(".","_");
    return arr;
  }

  renderImage(imageName, name) {
    let image
    let nameParts = name.split(" ")
    let initials = nameParts[0].substring(0,1) + nameParts[1].substring(0,1)

    try{
        image = <img className="profile-image" src={ `/images/${imageName}.jpg` } alt={imageName} />
    }
    catch(err){
      
        image = <div className="dummy-profile-image">{ initials }</div>
    }

    return image
  }

  render() {

    let imageName = this.getImageName(this.props.mail);

    return(
      <div className="profile">
        {
          this.renderImage(imageName, this.props.name)
        }
        <div className="profile-name">{this.props.name}</div>
        <div className="profile-icons">
          <a href={"tel:"+this.props.phone} 
             onMouseOver={this.handleMouseIn.bind(this,this.props.phone)} 
             onMouseOut={this.handleMouseOut.bind(this)}
             onClick={ () => { trackEvent("CONTACT", 'Phone '+this.props.name, "button"); } }
             target="_blank"
             ><IconPhone/></a>

          <a href={"mailto:"+this.props.mail} 
             onMouseOver={this.handleMouseIn.bind(this,this.props.mail)} 
             onMouseOut={this.handleMouseOut.bind(this)}
             onClick={ () => { trackEvent("CONTACT", 'Email '+this.props.name, "button"); } }
             target="_blank"
             ><IconMail/></a>

          <a href={this.props.linkedin}
             onClick={ () => { trackEvent("CONTACT", 'Linkedin '+this.props.name, "button"); } }
             target="_blank"
             ><IconLinkedin/></a>
        </div>
        <div className="profile-tooltip">{ this.state.tooltipText }&nbsp;</div>

      </div>
    );
  }
}