import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom'

const cookies = new Cookies();

export default class CookieBanner extends Component {

	constructor(props) {
		super(props);
		
		this.handleClick = this.handleClick.bind(this);
		this.checkCookie = this.checkCookie.bind(this);
		
		this.state = {
			hideBanner: this.checkCookie(),
		};
	}  
	
	handleClick() {		
		cookies.set('cookieInfo', true , { path: '/', maxAge: 31536000 });
		this.setState({hideBanner: true});
	}

	checkCookie() {
		const cookieValue = cookies.get('cookieInfo');

		if (cookieValue !== undefined) {
			return cookieValue;
		};
		return false;

	}

	render() {

		const hideBanner = (this.state.hideBanner) ? 'hide' : '';

		return (
			<div id="cookie-banner" className={ hideBanner }>
				<div className="cookie-banner-content">
				 Vi använder kakor på den här sidan för att förbättra den. <Link to="/integritetspolicy">Läs mer</Link>.
				</div>
				<div className="cookie-banner-accept-button" onClick= { this.handleClick } >
					Ok, vad coolt!
				</div>
			</div>
		);
	}
}