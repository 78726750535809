import React, { useRef } from 'react';
import { IconArrow } from './svg';

export const CustomerCase = ({isActive, setActiveCustomerCase, logo, bodyText}) => {

  const bodyRef = useRef();
  const containerRef = useRef();

  const handleClick = (e) => {   
    setActiveCustomerCase(containerRef);    
  }
 
    const visibility = ((isActive) ? "show" : "hide");

    return (
      <div className={"customer-case "+visibility } ref={ containerRef } onClick={ handleClick }>
        
        <div className={"customer-logo customer-logo-" + logo}></div>

        <IconArrow direction="right" />

        <div className="body-text-container" ref={ bodyRef }>
          <div className="body-text">
          { isActive && bodyText && bodyText.split(/\r?\n/).map((str, i) => { return <p key={i}>{str.trim()}</p> }) }
          </div>
        </div>   
               
      </div>
    );
}