import React from 'react';

export default function Integritetspolicy({setCurrentChapter}) {

  setCurrentChapter(0)

  return (
    <div id="privacy-policy" className="section-container">
      
      <div className="one-column">
        <div className="column">
          <h1>Integritetspolicy</h1>
          
          <p>
            Kayenta är ett konsultföretag som utvecklar våra modiga kunders analytiska förmåga. 
            För oss är det därför självklart att vi använder data för att utveckla vår egna verksamhet. 
            Det är också lika självklart att vi gör det på ett ansvarsfullt sätt. 
            Med undantag för din IP-adress (se avsnittet om webbanalysverktyg nedan) samlar vi inte in några personuppgifter. Vi delar inte heller med oss av någon annan information till tredje part.
            </p>
            <p>
            Här nedan kan du se vilken data som ditt besök på den här webbplatsen genererar och hur vi använder den. 
            Vill du att vi berättar mer om hur vi använder data, eller hur vi kan hjälpa dig göra detsamma för att öka din analytiska förmåga, får du gärna kontakta oss på <a href="mailto:kundresan@kayenta.se">kundresan@kayenta.se</a>
          </p>

          <h2>Kakor</h2>
          <p>Vi använder kakor på den här webbplatsen. Inga personuppgifter lagras i någon av dem. Här är en förteckning över dem vi använder:</p>
          
          <table>
            <tbody>
            <tr>
              <th>Kaka</th>
              <th>Syfte</th>
              <th>Livslängd</th>
            </tr>
            <tr>
              <td className="strong">_ga</td>
              <td>Används av Google Analytics för att identifiera en webbläsare.</td>
              <td>Permanent kaka som sparas i två år.</td>
            </tr>
            <tr>
              <td className="strong">_gat</td>
              <td>Används av Google Analytics för att identifiera en webbläsare per dag.</td>
              <td>Kaka som sparas i 24 timmar.</td>
            </tr>
            <tr>
              <td className="strong">_gid</td>
              <td>Används av Google Analytics att webbläsare inte ska skicka oäkta sidvisningar.</td>
              <td>Kaka som sparas i en minut.</td>
            </tr>
            <tr>
              <td className="strong">cookieInfo</td>
              <td>Används för att komma ihåg om kakor accepterats eller inte.</td>
              <td>Permanent kaka som sparas i ett år.</td>
            </tr>
            </tbody>
          </table> 

          <h2>Webbanalysverktyg</h2>
          <p>Vi använder webbanalysverktyget Google Analytics på den här webbplatsen. Syftet med det är att ge oss insikter för hur vi kan öka dess användarupplevelse.</p>
          <p>Informationen som skapas av din användning av webbplatsen (inklusive din IP-adress) vidarebefordras till och lagras av Google på servrar i USA. Google kan också överföra denna information till tredje parter om det krävs enligt lag eller i de fall en tredje part behandlar informationen för Googles räkning. Google kommer inte att koppla samman denna information med andra data som Google innehar. Läs mer om detta i <a href="https://marketingplatform.google.com/about/analytics/terms/se/" target="_blank" rel="noopener noreferrer">Googles användarvillkor för Google Analytics.</a></p>

          <h2>Stänga av webbanalysverktyget</h2>

          <p>Om du inte vill att vi samlar in information om hur du använder webbplatsen så kan du installera <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out</a> i din webbläsare. Det är ett insticksprogram som gör att du kan avaktivera Google Analytics.</p>

          <h2>Stänga av alla kakor</h2>

          <p>Du som besökare kan helt förhindra att webbplatsen sparar kakor på din dator. Du kan även ställa in webbläsaren så att du får en varning varje gång webbplatsen försöker spara en kaka på din dator. Du gör båda dessa inställningar via din webbläsares säkerhetsinställningar.</p>

          <p>Denna information uppdaterades senast 2019-09-02.</p>
        
        </div>
      </div>
    </div>
  );

}