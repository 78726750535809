import ReactGA from 'react-ga';

/**
 * trackPage - Add custom tracking page.
 @param {string} page relative URL for current page. Begin with "/". 
 */
export function trackPage(page) {
  ReactGA.pageview(window.location.pathname + page);
};

/**
 * trackEvent - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export function trackEvent(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export function trackScroll(elementId, previousScrollPos) {
	var currScrollPos = window.pageYOffset+(window.innerHeight || document.documentElement.clientHeight);
	var prevScrollPos = previousScrollPos+(window.innerHeight || document.documentElement.clientHeight);
	var element = document.getElementById(elementId)

	const elOffset = element.offsetTop+element.offsetHeight;
	  
	if (prevScrollPos < elOffset && currScrollPos > elOffset) {
	  trackEvent("SCROLLING", "Scrolled down to " + elementId, "page scroll");
	} else if (prevScrollPos > elOffset && currScrollPos < elOffset) {
	  trackEvent("SCROLLING", "Scrolled up to " + elementId, "page scroll");
	}
}