import React, { useRef } from 'react';
import { IconArrow } from './svg';

export const CustomerCaseSummary = ({isActive, setActiveCustomerCase, logo, titleText, bodyText}) => {
  
    const bodyRef = useRef();
    const containerRef = useRef();


  const handleClick = () => {    
    setActiveCustomerCase();
  }

  const toggleMenu = () => {

    const element = this.bodyRef.current;

    if (!this.props.isActive) {

      const container = this.containerRef.current;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementHeight = element.scrollHeight
      const windowHeight = window.innerHeight; 
      const containerPadding = container.style.paddingBottom;     

      const offsetPosition = -bodyRect + elementHeight + elementRect + containerPadding - windowHeight;     

      if (offsetPosition > window.pageYOffset) { 
        window.scrollTo({
             top: offsetPosition,
             behavior: "smooth"
        });
      }

    } else {
      //element.style.height = 0;
    }
  }

    const visibility = ((isActive) ? "show" : "hide");
    const arrowDirection = ((isActive) ? "down" : "right");

    return (
      <div className={"customer-case-summary "+visibility } ref={ containerRef } onClick={ handleClick }>
        
        <div className={"customer-logo customer-logo-" + logo}></div>

        <div className="title-text" >
          <div className="title-text-arrow"><IconArrow direction={ arrowDirection } /></div>
          <span>{ titleText }</span>
        </div>

          <div className="body-text-container" ref={ bodyRef }>
            <div className="body-text">
            { bodyText.split(/\r?\n/).map((str, i) => { return <p key={i}>{str.trim()}</p> }) }
            </div>
          </div>            
      </div>
    );
}