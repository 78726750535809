import React, { forwardRef } from 'react';
import { CustomerCase } from '../CustomerCase';
import { customerCases } from '../../data/customerCases';
import { ImgVennChart } from '../svg';
import IconValue from "../../assets/img/icon-value.svg";
import IconEnabler from "../../assets/img/icon-enabler.svg";

const _AnalytiskaFormagan = ({activeCustomerCaseId, setActiveCustomerCase, isActive, noneIsActive, chapter}, ref) => {

    return (
      <div id="analytiska-formagan" className="section-container" ref={ref}>
        
        <div className="section-title">
          <div className="chapter-number">
            { chapter }
          </div>
          <span className="small-text">Vi utvecklar den</span>
          <span className="large-text">analytiska förmågan</span>
          <span className="small-text">hos modiga företag</span>
        </div>

        <div className="one-column">
          <div className="column">
            <p>Ett företags analytiska förmåga är det affärsvärde ett företag kan utvinna ur sin data. Det är därför Kayenta arbetar med att utveckla människa, teknik och process - det ger störst affärsvärde.</p>
          </div>
        </div>

        <div className="two-column">
          <div className="column">
            
            <div className="row">
              <img className="icon" src= { IconEnabler } alt="IconEnabler" />
              <p className="right">Den analytiska förmågan bestäms av kombinationen av möjliggörarna <strong>människa</strong>, <strong>teknik</strong> och <strong>process</strong>. </p>
            </div>

            <div className="row">
              <img className="icon" src= { IconValue } alt="IconValue" />
              <p className="right">En möjliggörare ger i sig själv endast en begränsad möjlighet till att skapa värde. Det är när de verkar tillsammans som stort affärsvärde skapas genom <strong>innovation</strong>, <strong>automation</strong> eller <strong>skalning</strong>.</p>
            </div>
          </div>

          <div className="column">
            <ImgVennChart />
          </div>
        </div> 

        <div className={"customer-cases " + noneIsActive() }>

          { Object.entries(customerCases).map(([key, item], i) => {

            return (
              <CustomerCase 
                key={key}
                isActive = { isActive(i+1) }
                setActiveCustomerCase = { (e) => setActiveCustomerCase(i+1, e) }
                logo = {key}
                bodyText={item.analytiskaFormaganText}
              />
            )
          })
        }
        </div>
      </div>
    );
  }


export const AnalytiskaFormagan = React.memo(forwardRef(_AnalytiskaFormagan));

