import React, { Component } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { trackEvent } from '../utils/ga';

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  
  handleClick(page) {
    this.props.handleClick(page);
  }

  render() {
    return (
      <div
        id="nav-menu"
        className={this.props.menuVisibility}
      >
        <div className="nav-menu-items">   
          <NavHashLink 
            to="/#modiga-foretag" 
            scroll={ this.handleScroll }
            onClick={ (el) => this.handleClick("/modiga-foretag") } >
            <span className="large-text">Modiga Företag</span>
            <span className="small-text">har förutsättning att utveckla sin analytiska förmåga</span>
          </NavHashLink>

          <NavHashLink
            to="/#analytiska-formagan" 
            scroll={ this.handleScroll }
            onClick={ (el) => this.handleClick("/analytiska-formagan") } >
            <span className="large-text">Analytiska Förmågan</span>
            <span className="small-text">är det affärsvärde ett företag kan utvinna ur sin data</span>
          </NavHashLink>

          <NavHashLink 
            to="/#utvecklar" 
            scroll={ this.handleScroll }
            onClick={ (el) => this.handleClick("/utvecklar") } >
            <span className="large-text">Utvecklar</span>
            <span className="small-text">människor, teknik och processer</span>
          </NavHashLink>

          <NavHashLink 
            to="/#vi" 
            scroll={ this.handleScroll }
            onClick={ (el) => this.handleClick("/vi") } >
            <span className="large-text">Vi</span>
            <span className="small-text">är Kayenta!</span>
          </NavHashLink>
        </div>

        <div className="nav-menu-footer">
          <div className="nav-menu-footer-items">
            <a href="https://goo.gl/maps/uJHW4z3yRchMogKP6" 
               target="_blank" 
               rel="noopener noreferrer"
               onClick={ () => { trackEvent("CONTACT", 'Click menu address link to Google Maps', "button"); } }
            >
              Wallingatan 32, 111 24 Stockholm
            </a>
          </div>
        </div>
      </div>
    );
  }
}