import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { trackEvent } from '../../utils/ga';

const _FortsattaResan = () => {

  return (
    <div id="fortsatta-resan">

        <h3>Hur vill du fortsätta resan med oss?</h3>
        
        <div className="buttons">
          <a href="mailto:rekrytering@kayenta.se" onClick={ () => { trackEvent("INTERACT", 'Click "Continue as Employee" button', "button"); } }>
          <div className="button">
            <span className="small-text">som</span>
            <span className="large-text">anställd</span>
          </div>
          </a>

          <a href="mailto:kundresan@kayenta.se" onClick={ () => { trackEvent("INTERACT", 'Click "Continue as Customer" button', "button"); } }>
          <div className="button">
            <span className="small-text">som</span>
            <span className="large-text">kund</span>
          </div>
          </a>
        </div>
        </div>
  );
}

export const FortsattaResan = React.memo(_FortsattaResan)