import React, { forwardRef, useRef, useEffect } from 'react';
import { CustomerCase } from '../CustomerCase';
import { customerCases } from '../../data/customerCases';
import { employees } from '../../data/employees';
import Profile from '../Profile';

import { trackEvent } from '../../utils/ga';

const _Vi = ({activeCustomerCaseId, setActiveCustomerCase, isActive, noneIsActive, chapter}, ref) => {

  const imageRef = useRef();

  /*useEffect(() => {
    
    window.addEventListener('scroll', handleScroll);  
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  const handleScroll = (el) => {    
    if (window.innerHeight > window.innerWidth) {
      let image = imageRef.current;
      let startPos = 0.5;
      let endPos = 1;

      let imagePos = 1-image.getBoundingClientRect().top/window.innerHeight;

      //let drawPos = imagePos-0.3;
      let drawPos = 1- (endPos-imagePos)/(endPos-startPos);

      if (drawPos >= 0 && drawPos <= 0.8) {
        image.style.backgroundPosition = ((drawPos+0.1)*100).toString() + "% 10%"
      } else if (drawPos < 0) {
        image.style.backgroundPosition = "10% 10%"
      } else {
        image.style.backgroundPosition = "90% 10%"
      }
    }
  }*/


  return (
    <div id="vi" className="section-container" ref={ref}>
      
      <div className="section-title">
        <div className="chapter-number">
          { chapter }
        </div>
        <span className="large-text">Vi</span>
        <span className="small-text">utvecklar den analytiska förmågan hos modiga företag</span>
      </div>

      {/*<div className="group-photo" ref={ imageRef }></div>*/}

      <div className="two-column">

        <div className="column">
          <p><strong>Vi</strong> på Kayenta är personer med stort engagemang och mod. Alla har lång erfarenhet av analytisk förflyttning och arbetar ofta i gränslandet mellan verksamhet och IT. Vi har som roligast när vi lär oss nya verksamheter och ny teknik samt när vi gör insatser som ger långvarig effekt för våra modiga kunder.</p>
        </div>

        <div className="column">
          <p><strong>Kayenta</strong> är ett konsultbolag vars stora nätverk i branschen gör att vi är duktiga på att matcha personers kompetens och driv med modiga företag som vill utveckla sin analytiska förmåga. Kayenta har som mål att bli ett ännu bättre företag genom att växa med medarbetare som vill vara delaktiga i att vidareutveckla både kunder och oss själva.</p>
        </div>
        
      </div>

      <div className={"customer-cases " + noneIsActive() }>

          { Object.entries(customerCases).map(([key, item], i) => {

            return (
              <CustomerCase 
                key={key}
                isActive = { isActive(i+1) }
                setActiveCustomerCase = { (e) => setActiveCustomerCase(i+1, e) }
                logo = {key}
                bodyText={item.viText}
              />
            )
          })
        }
        </div>

      <div className="profile-container">
      {
        employees.map( (employee, index) => {
          return <Profile 
            key = {index}
            name={ employee.name }
            phone={ employee.phone }
            mail={ employee.mail }
            linkedin={ employee.linkedin }
          />
        })
      }
      </div>
    </div>
  );
}


export const Vi = React.memo(forwardRef(_Vi));


