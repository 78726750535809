import React from 'react';
import { NavHashLink } from 'react-router-hash-link';

export const Progressbar = ({currentChapter}) => {

  const isCurrentChapter = (id) => {
    if (currentChapter === id) {
      return "current"
    } else if (currentChapter < id) {
      return "future"
    }
    return ""
  }

  const showProgressBar = () => {
    if (currentChapter > 0) {
      return ""
    } 
    return "hide"
  }

  const handleScroll = (el) => {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <div className={"progressbar " + showProgressBar()}>
      <div className={"chapter " + isCurrentChapter(1)}>
        <div className="bullet"></div>
        <NavHashLink 
          to="/#modiga-foretag" 
          scroll={ handleScroll }
          >
          <div className="label">Modiga Företag</div>
        </NavHashLink>
      </div>

      <div className={"path " + isCurrentChapter(2)}></div>

      <div className={"chapter " + isCurrentChapter(2)}>
        <div className="bullet"></div>
        <NavHashLink 
          to="/#analytiska-formagan" 
          scroll={ handleScroll }
          >
          <div className="label">Analytiska Förmågan</div>
        </NavHashLink>
      </div>

      <div className={"path " + isCurrentChapter(3)}></div>

      <div className={"chapter " + isCurrentChapter(3)}>
        <div className="bullet"></div>
        <NavHashLink 
          to="/#utvecklar" 
          scroll={ handleScroll }
          >
          <div className="label">Utvecklar</div>
        </NavHashLink>
      </div>

      <div className={"path " + isCurrentChapter(4)}></div>

      <div className={"chapter " + isCurrentChapter(4)}>
        <div className="bullet"></div>
        <NavHashLink 
          to="/#vi" 
          scroll={ handleScroll }
          >
          <div className="label">Vi</div>
        </NavHashLink>
      </div>
    </div>
  );
}

