import React, { forwardRef, memo } from 'react';
import { CustomerCaseSummary } from '../CustomerCaseSummary';
import { customerCases } from '../../data/customerCases';
import { ScrollButton } from '../ScrollButton';

const _Kundcase = ({activeCustomerCaseId, setActiveCustomerCase, nextPage, isActive}, ref) => {

  const scrollButtonText = activeCustomerCaseId ? "Följ med på " + Object.values(customerCases)[activeCustomerCaseId-1].name + "s resa" : "Följ med på resan"

  return (
    <div id="kundcase" ref={ref} className="section-container">
      
      <div className="kundcase-content">
        <div className="one-column">
          <div className="column">
            <p>Några av de modiga företag som gjort resan tillsammans med oss</p>
          </div>
        </div>

        <div id="customer-case-summaries">

        { Object.entries(customerCases).map(([key, item], i) => {

            return (
              <CustomerCaseSummary 
                key={key}
                isActive = { isActive(i+1) }
                setActiveCustomerCase = { (e) => setActiveCustomerCase(i+1) }
                logo = {key}
                titleText={item.summaryTitle}
                bodyText={item.summaryText}
              />
            )
          })
        }

        </div>

        <ScrollButton 
          text={scrollButtonText}
          animated={true}
          scrollTarget={nextPage}
          />

      </div>
    </div>
  );
}

export const Kundcase = React.memo(forwardRef(_Kundcase));