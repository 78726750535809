import React from 'react';
import { IconArrow } from './svg';

export const ScrollButton = ({text, animated, scrollTarget}) => {

  const isAnimated = animated ? "animated" : "";

  const handleClick = (el) => {
    scrollTarget.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
        <div className={"scroll-down-button " + isAnimated } onClick={handleClick}>
          <div className="scroll-down-button-text">
            {text}
          </div>
          <div className="scroll-down-button-icon">
            <IconArrow direction={ "down" } />
          </div>
        </div>
  );
}