import React, { Component } from 'react';
import NetworkGraph from "../../assets/img/network-graph.svg";
import { ScrollButton } from '../ScrollButton';

const _StartPage = ({nextPage}) => {

  return (
    <div className="section-container">
      <img id="start-page-background-img-1" src= { NetworkGraph } alt="" />
      <img id="start-page-background-img-2" src= { NetworkGraph } alt="" />
      
      <div className="start-page-content" >

        <div id="start-page-text">
          Vi utvecklar den<br/>analytiska förmågan<br/>hos modiga företag
        </div>

        <ScrollButton 
          text="Modiga företag"
          animated={true}
          scrollTarget={nextPage}
          />
      </div>
    </div>
  );
}

export const StartPage = React.memo(_StartPage)