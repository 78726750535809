import React, { forwardRef } from 'react';
import { CustomerCase } from '../CustomerCase';
import { customerCases } from '../../data/customerCases';
import { ImgParallellCoordinates } from '../svg';

const _Utvecklar = ({activeCustomerCaseId, setActiveCustomerCase, isActive, noneIsActive, chapter}, ref) => {

    return (
      <div id="utvecklar" className="section-container" ref={ref}>
        
        <div className="section-title">
          <div className="chapter-number">
            { chapter }
          </div>
          <span className="small-text">Vi</span>
          <span className="large-text">utvecklar</span>
          <span className="small-text">den analytiska förmågan hos modiga företag</span>
        </div>

        <div className="one-column">
          <div className="column">
            <p>Möjliggörarna för analytisk förmåga kan delas upp i strategiska och taktiska utvecklingsbara komponenter. Människa delas upp i <strong>kompetens</strong> & <strong>styrning</strong>, Process i <strong>vision</strong> & <strong>arbetssätt</strong> och Teknik i <strong>arkitektur</strong> & <strong>verktyg</strong>.
            Med dessa mer konkreta komponenter blir det tydligt hur den totala analytiska förmågan kan utvecklas.</p>  
          </div>
          <div className="column">
            <p>Vi på Kayenta har djup förståelse för och lång erfarenhet av att arbeta med alla dessa delar. Det är därför vi kan utveckla den analytiska förmågan hos företag - bara de är tillräckligt modiga.</p>
          </div>

          <div className="column">
            <ImgParallellCoordinates />
          </div>
        </div>

        <div className={"customer-cases " + noneIsActive() }>

          { Object.entries(customerCases).map(([key, item], i) => {

            return (
              <CustomerCase 
                key={key}
                isActive = { isActive(i+1) }
                setActiveCustomerCase = { (e) => setActiveCustomerCase(i+1, e) }
                logo = {key}
                bodyText={item.utvecklarText}
              />
            )
          })
        }
        </div>

      </div>
    );
  }


export const Utvecklar = React.memo(forwardRef(_Utvecklar));

