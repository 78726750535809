import React, { forwardRef } from 'react';
import { CustomerCase } from '../CustomerCase';
import { customerCases } from '../../data/customerCases';
import IconLightBulb from "../../assets/img/icon-light-bulb.svg";
import IconEye from "../../assets/img/icon-eye.svg";
import IconFlash from "../../assets/img/icon-flash.svg";

const _ModigaForetag = ({activeCustomerCaseId, setActiveCustomerCase, isActive, noneIsActive, chapter}, ref) => {

    return (
      <div id="modiga-foretag" ref={ref} className="section-container">
        
        <div className="section-title">
          <div className="chapter-number">
            { chapter }
          </div>
          <span className="small-text">Vi utvecklar den analytiska förmågan hos</span>
          <span className="large-text">modiga företag</span>
        </div>

        <div className="one-column">
          <div className="column">
            <p>Modiga företag har följande egenskaper vilka ger dem förutsättning att utveckla sin analytiska förmåga. Det är därför som Kayenta arbetar med modiga företag.</p>
          </div>
        </div>

        <div className="three-column">
          <div className="column">
            <img className="icon" src= { IconLightBulb } alt="IconLightBulb" />
            <p>Det modiga företaget <strong>vet varför</strong> de vill utveckla sin analytiska förmåga.</p>
          </div>

          <div className="column">
            <img className="icon" src= { IconEye } alt="IconEye" />
            <p>Det modiga företaget har ett <strong>öppet sinne</strong> för att ta till sig de förändringar som krävs för att ge effekt.</p>
          </div>

          <div className="column">
            <img className="icon" src= { IconFlash } alt="IconFlash" />
            <p>Det modiga företaget har <strong>kraft</strong> att genomföra de förändringar som får effekt.</p>
          </div>
        </div>

        <div className={"customer-cases " + noneIsActive() }>

          { Object.entries(customerCases).map(([key, item], i) => {

            return (
              <CustomerCase 
                key={key}
                isActive = { isActive(i+1) }
                setActiveCustomerCase = { (e) => setActiveCustomerCase(i+1, e) }
                logo = {key}
                bodyText={item.modigaForetagText}
              />
            )
          })
        }
        </div>
      </div>
    );
}

export const ModigaForetag = React.memo(forwardRef(_ModigaForetag));

