import React, { Component } from 'react';

export default class HamburgerButton extends Component {
  
  handleClick() {
    this.props.toggleMenu();
  }
  
  render() {
    return (
      <div 
        className="burger-container"
        onClick={ this.handleClick.bind(this) }
      >
        <div id="burger">
          <div className="bar topBar"></div>
          <div className="bar middleBar"></div>
          <div className="bar bottomBar"></div>
        </div>
      </div>
    )
  }
}