import React, { useRef, useEffect } from 'react';

const _StoryLine = ({chapter, activeCustomerCaseId}) => {
  
  const pathRef = useRef()
  const gradientRef = useRef()

  const activeCustomerCase = activeCustomerCaseId || 2

  useEffect( () => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    
  },[])

  const handleScroll = (el) => {
    let path = pathRef.current;
    let gradient = gradientRef.current;

    // Landscape screen aspect ratio
    let startPos = 0.2;
    let endPos = 0.8;

    // Portrait screen aspect ratio
    if (window.innerHeight < window.innerWidth) {
      startPos = 0.3;
      endPos = 1.2;
    }


    let pathPos = 1-path.getBoundingClientRect().top/window.innerHeight;

    let drawPos = 1- (endPos-pathPos)/(endPos-startPos);

    // Get length of path...
    var pathLength = path.getTotalLength();

    // Make very long dashes (the length of the path itself)
    path.style.strokeDasharray = pathLength + ' ' + pathLength;

    // Offset the dashes so the it appears hidden entirely
    path.style.strokeDashoffset = pathLength;

    if (drawPos>1) {
      drawPos = 1;
    } 

    gradient.setAttribute('y2',String(Math.round(drawPos*100))+"%");

    // Length to offset the dashes
    var drawLength = pathLength * drawPos;
    
    // Draw in reverse, i.e. when scrolling up
    path.style.strokeDashoffset = pathLength - drawLength;
  }

  let paths = {
    left: [
      "M48.63,0.672c0.676,66.521 100.84,95.578 101.371,148.656",
      "M63.461,0.672c36.174,52.247 112.721,56.041 121.055,79.329c7.734,21.614 -34.982,37.601 -34.515,69.327"
      ],
    center: [
      "M134.113,-0c-0.193,16.516 -60.753,41.252 -60.592,67.881c0.226,37.535 76.48,55.56 76.48,81.447",
      "M150.006,0.672c-0.497,40.791 26.025,49.768 25.544,77.387c-0.426,24.487 -25.891,47.565 -25.542,71.269"
      ],
    right: [
      "M254.975,0.672c-0.701,66.521 -104.426,95.578 -104.976,148.656",
      "M240.071,0.672c-0,14.225 -14.162,19.05 -12.785,40.193c1.278,19.617 8.837,32.164 -2.138,46.858c-14.655,19.622 -75.496,37.901 -75.147,61.605"
      ]
  };

  let path = Object.values(paths)[activeCustomerCase-1][chapter % 2]

  return (
    <div className="story-line-container">
      <div className="ie-svg-scale-fix story-line">
        <canvas height="150" width="300"></canvas>
        <svg className="" preserveAspectRatio="xMinYMin meet" viewBox="0 0 300 150" style={{fillRule:"evenodd", clipRule:"evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:"1.5"}}>
               
          <defs>
            <linearGradient ref={ gradientRef } id={ "linear-" + chapter } x1="0" y1="0" x2="0" y2="0" gradientUnits="userSpaceOnUse">
              <stop offset="0" style={{stopColor:"rgb(244,242,239)", stopOpacity:"1"}}/>
              <stop offset="1" style={{stopColor:"rgb(34,30,32)", stopOpacity:"1"}}/>
            </linearGradient>

            <linearGradient id="_StoryLineLinear" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
              <stop offset="0" style={{stopColor:"white", stopOpacity:"1"}}/>
              <stop offset="0.2" style={{stopColor:"rgb(244,242,239)", stopOpacity:"1"}}/>
            </linearGradient>

            <mask id={ "mask-" + chapter } >
                <path className="mask" d={ path } style={{fill:"none", strokeDasharray:"4,4,0,0"}} />          
            </mask>
          </defs>

          <path d={ path } style={{fill:"none", stroke:"url(#_StoryLineLinear)", strokeDasharray:"4,4,0,0"}} />

          <path ref={ pathRef } mask={ "url(#mask-" + chapter + ")" } d={ path } style={{fill:"none", stroke:"url(#linear-" + chapter + ")", strokeDasharray:"4,4,0,0"}} />

        </svg>
      </div>
    </div>
  );
}

export const StoryLine = React.memo(_StoryLine)
