export const customerCases = {
	ica: {
		  name: "ICA"
		, summaryTitle: "Vi införde Self Service Analytics på ICA"
		, summaryText: 
			`ICA Sverige ville öka sin konkurrenskraft genom att bli bättre på att förstå sin egen affär. Receptet var Self Service Analytics med Tableau som front-end.
			Kayenta tog fram lösningsarkitekturen och ledde utrullningen av Tableau på hela ICA Sverige. Inom ett år hade plattformen 1500 unika användare.`
	    , modigaForetagText: 
	    	`ICA Sverige ville införa Self-Service Analytics för att få nya insikter i vad som driver deras affär och därmed få ökad konkurrenskraft. De visste varför de ville utveckla sin analytiska förmåga.
            Projektet fick stor frihet att självständigt driva nödvändiga frågor för att nå det strategiska målet. Verksamheten hade ett öppet sinne till förändringar i arbetssätt.
            Kraften till förflyttningen fanns i och med säkrad finansiering, tillgång till erfarna interna resurser samt utrymme för användarna att utvecklas i sin takt.`
	    , analytiskaFormaganText: 
	    	`ICA Sverige hade målbilden att fler skulle kunna använda data som en gemensam tillgång för Self-Service Analys. De ville alltså främst utveckla sin analytiska förmåga genom skalning. Även innovation och automation fanns med i målbilden men med lägre prioritet.
			Därför låg fokus på att utveckla möjliggörarna process och människa. Nya tekniska möjliggörare fanns med som en del i projektet för att komplettera befintlig arkitektur med verktygsstöd för visualisering.`
	    , utvecklarText:
	    	`Kompetensen hos människorna på IT och i verksamheten utvecklades genom ett stort utbildningspaket i verktyg, arbetssätt och att genomföra analys. Det gav ICA förmågan att själva fortsätta utvecklas utan beroenden till konsulter.
			Processen utvecklades genom att nya arbetssätt för Self Service Analytics togs fram i samarbete med verksamheten och IT. Det innebar nya roller och ansvar samt en ny struktur för governance.
			Vi införde ett nytt verktyg i form av Tableau för visualisering av data och anpassade befintlig arkitektur för dataleverans och katalogisering av data till detta.`
	    , viText: 
	    	`Genom etableringen av Tableau i alla verksamhetsfunktioner på ICA Sverige utökade vi vår erfarenhet av breda införanden av Self Service Analytics i stora organisationer.
			Utveckling av teknik och process gick relativt enkelt medan utveckling och förändringsledning av människor i olika roller krävde mycket mer tid och energi.
			Slutresultatet i projektet för att öka förståelsen av ICA Sveriges affär genom Self Service Analytics, var efter ett års tid 1500 unika användare och en nöjd kund.`

	},

	globalconnect: {
		  name: "GlobalConnect"
		, summaryTitle: "Vi skapade ett analytics team på GlobalConnect"
		, summaryText: 
			`Efter en initial utvärdering av den analytiska förmågan hos GlobalConnect tog Kayenta fram en vision för analys, grundade ett analysteam och byggde en ny modern analysplattform. 
			Ett år senare hade analysteamet växt till åtta personer, det gamla warehouset blivit migrerat till nya plattformen och organisationen hade lärt känna sin affär mycket bättre.`
		, modigaForetagText: 
			`GlobalConnect hade som uttalat mål att bli bäst i branchen och insåg att de hade en outnyttjad potential i sin data. 
			Man förstod tidigt att ett starkt analysteam var en nyckelfaktor och satsade därför de resurser som krävdes. Företaget var öppna för den senaste tekniken och tog intresserat till sig nya moderna arbetssätt i teamet.`
	    , analytiskaFormaganText: 
	    	`GlobalConnect saknade en samlad analyskompetens vilket gjorde att flera delar av verksamheten hade sin egen bild av affären. 
	    	Data var inte heller en integrerad del i affärsprocesserna vilket gjorde det svårt att automatiersa och skala den analytiska förmågan. 
	    	Den befintliga tekniska plattformen var ytterligare en försvårande faktor för att möjliggöra förflyttningen som eftersträvades.`
	    , utvecklarText:
	    	`Vi arbetade hela tiden hands-on tillsammans med verksamheten och IT för att genomföra nya djupare analyser. Samtidigt utbildade vi analytics teamets produktägare och coachade stakeholders att tänka datadrivet för att förankra vår vision och driva på en förändring av verksamhetens arbetssätt. 
			Detta möjliggjordes genom att vi etablerade en modern arkitektur och implementerade en ny skalbar teknisk lösning baserad på Azure, Snowflake och QlikSense. Plattformen knöts ihop med ett väl fungerande CI/CD flöde och en samlad övervakning av hela miljön.`
	    , viText: `Vi har på GlobalConnect fått närmast optimala förutsättningar för att tillsammans utveckla deras analytiska förmåga. Som vanligt var tekniken den enkla biten medan förändrigen i organisation och arbetssätt var mer utmanande. 
	    		   Det var väldigt roligt och lärorikt att genomföra ett uppdrag där kraften i att utveckla människa, process och teknik tillsammans blir så tydlig.`
	},

	ncc: {
		  name: "NCC"
		, summaryTitle: "Vi gjorde en analytisk resa tillsammans med NCC"
		, summaryText: 
			`NCC ville förbätta sin position på marknaden genom att utnyttja skalfördelar som stor spelare inom bygg och anläggning. Metoden var att bli en datainformerad verksamhet, och en väsentlig del i att nå detta mål var att ha en central analytisk förmåga. 
			För att åstadkomma det definierade vi ett framtida läge för organisation, process, styrning och arkitektur för analytics. Tillsammans med NCC realiserade vi planen genom ett stegvist införande med gradvis ökad värderealisering.`
	    , modigaForetagText: 
	    	`NCC hade modet och kraften att definiera ett framtida läge innan de började realisera nya analytiska förmågor för att skapa värde. 
	    	Tack vare det kunde vägval och prioriteringar under resan på ett bra sätt kopplas till konsekvenser i relation till slutmålet. 
	    	Resultatet blev en snabb och effektiv förflyttning som säkerställde en snabb värderealisering.`
	    , analytiskaFormaganText: 
	    	`NCC ville gå från en väldigt decentraliserad struktur för analys till en mer effektiv struktur där det fanns möjlighet till att återanvända och sprida insikter med skalfördelar. 
	    	För att åstadkomma det och samtidigt dra nytta av redan utvecklade förmågor skapades en famtida målbild. Den omfattade arkitektur, arbetssätt och organisation för att skapa en hybrid med centrala och decentraliserade lösningar i samma analytiska plattform. Det resulterade i en snabbhet i att realisera värde med befintlig data och kompetenser. 
	    	Utmaningan var att det krävdes en relativt stor apparat för styrning för att få ihop olika delar i teknik, organisation och inte minst förväntan. `
	    , utvecklarText:
	    	`Målet var att skapa centrala förmågor och stöd för att göra NCC till en datainformerad organisation med analys.
			För att åstadkomma det definierade vi ett framtida läge för:
			- Arkitektur inklusive vilka verktyg som var prioriterade att användas först
			- Kompetens som behövdes i gemensamma rollbeskrivningar samt hur kapaciten skulle säkras och styras.
			- Vilken analytisk effekt som skulle uppnås samt vilken organisation, arbetssätt och övrigt stöd som behövdes för att nå den.
			För att säkerställa att utvecklingen gick enligt plan följde vi upp progress med nyckeltal och att användandet av de förmågor vi etablerade ökade i önskad takt.`
	    , viText: 
	    	`Vi var ett team med flera seniora personer med erfarenhet av alla delar som krävs för att genomföra en analytisk förflyttning som fungerar i praktiken. 
	    	Nyckeln till en snabb etablering av en effektiv analytisk förmåga på NCC var att tillsammans och på ett pristigelöst sätt göra anpassningar på organisation, processer, teknik och styrning till de förutsättningar som rådde.`
	}
}