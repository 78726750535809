export const employees = [

	{	
		name:		"Carl Svärd",
		phone:		"+4670-878 94 79",
		mail:		"carl.svard@kayenta.se",
		linkedin:	"https://www.linkedin.com/in/carl-svard/"
	},
	{	
		name:		"David Svensson",
        phone:		"+4670-674 62 23",
        mail:		"david.svensson@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/davidsvnssn"
	},
	{
		name:		"Dennis Strahl",
        phone:		"+4673-439 07 09",
        mail:		"dennis.strahl@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/dennis-strahl"
	},
	{
		name:		"Fabian Karlsson",
        phone:		"+4670-373 46 84",
        mail:		"fabian.karlsson@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/fabiankarlsson"
	},
	{
		name:		"Herman Hillberg",
        phone:		"+4673-439 06 49",
        mail:		"herman.hillberg@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/hermanhillberg"
	},
	{
		name:		"Jonas Gullin",
        phone:		"+4673-625 51 03",
        mail:		"jonas.gullin@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/jonas-gullin-2427411"
	},
	{
		name:		"Jonas Nilsson",
        phone:		"+4670-750 97 30",
        mail:		"jonas.nilsson@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/jonas-nilsson-43ab032"
	},
	{
		name:		"Karl-Oskar Johannesson",
        phone:		"+4679-339 12 95",
        mail:		"karl.oskar.johannesson@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/kalle-johannesson-6a618930"
	},
	{
		name:		"Patrick Baumann",
        phone:		"+4670-855 04 69",
        mail:		"patrick.baumann@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/patrick-baumann"
	},
	{
		name:		"Robin Bolin",
        phone:		"+4670-311 72 81",
        mail:		"robin.bolin@kayenta.se",
        linkedin:	"https://www.linkedin.com/in/robinbolin"
	},
]