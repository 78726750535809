import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import smoothscroll from 'smoothscroll-polyfill';

import Header from './Header';
import { Home } from './chapters/Home';
import { Progressbar } from './Progressbar';
import Footer from './chapters/Footer';
import CookieBanner from './CookieBanner';
import Integritetspolicy from './chapters/Integritetspolicy';
import ScrollToTop from './ScrollToTop';

//import { StoryLine } from './svg';

//import withEnhancement from './withEnhancement';

//import { trackEvent } from '../utils/ga';

// Initiate HOC's
//const ModigaForetagWithEnhancement = withEnhancement(ModigaForetag);
//const AnalytiskaFormaganWithEnhancement = withEnhancement(AnalytiskaFormagan);
//const UtvecklarWithEnhancement = withEnhancement(Utvecklar);
//const ViWithEnhancement = withEnhancement(Vi);

export default function App() {
  
  const [currentChapter, setCurrentChapter] = useState(0)

  useEffect(() => {
    // Polyfill for smooth scrolling
    smoothscroll.polyfill();
  }, [])

  return (
    <BrowserRouter>
      <div className="App" >  
        <Header />
        
        <Progressbar currentChapter={currentChapter} />

        <Switch>
          <ScrollToTop>
            <Route path="/" exact> <Home setCurrentChapter={setCurrentChapter} /> </Route>
            <Route path="/integritetspolicy"><Integritetspolicy setCurrentChapter={setCurrentChapter} /></Route>
          </ScrollToTop>
        </Switch>


        <Footer />

        <CookieBanner />

      </div>
    </BrowserRouter>

  );
}
